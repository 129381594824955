@media only screen and (min-width: 1280px) {
  .sol-enjoy-item:nth-child(odd) .sol-enjoy-border {
    background: linear-gradient(90deg, #00ddff00 0%, #ee00ffe3 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 20px 0px 29px -15px #c500ee;
    justify-content: flex-end;
    text-align: right;
  }
  .sol-nav-menu {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    width: 100%;
    display: contents;
  }
  .container .sol-introduce {
    padding: 0 20px;
  }
  .footer .footer-follow {
    width: 100%;
  }
  .sol-nav-menu {
    display: none;
  }
  .sol-enjoy-item img {
    width: 300px;
  }
  .sol-enjoy-item {
    margin-bottom: 50px !important;
  }
  .sol-rabbit-gun {
    display: grid;
    grid-gap: 20px;
  }
  .sol-introduce .sol-rabbit-blue {
    width: 100%;
    margin: 20px 0;
  }
  /* .sol-enjoy-border {
    width: auto;
  } */
  .sol-section {
    width: 100%;
  }
  .footer .footer-follow {
    margin: 50px 0;
  }
  .sol-rabbit-gun {
    grid-template-columns: auto auto auto;
    margin: 0 0 70px;
  }
  .sol-faq {
    padding: 0 20px;
  }
  .sol-faq .sol-section {
    margin-bottom: 50px;
  }

  .sol-roadmap .sol-enjoy-item {
    padding: 0;
  }
  .sol-enjoy-item {
    flex-direction: column-reverse !important;
  }
  .sol-roadmap .sol-enjoy-item img {
    width: 200px;
  }

  .sol-enjoy-content {
    width: 100%;
  }
  .sol-faq .sol-enjoy-border {
    height: auto;
    padding: 20px 40px;
  }
  .sol-faq .sol-section h2 {
    padding: 40px 60px 0px;
  }
  .sol-faq .sol-section h3 {
    padding: 0px 60px 0px;
  }
  .sol-enjoy h1 {
    padding: 100px 0 71px;
  }
  .sol-connected {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -30px;
  }
  .sol-connected .sol-balance {
    width: 150px;
  }
  .sol-header {
    padding: 20px;
    justify-content: flex-end;
  }
  button.sol-connect-wallet {
    margin-top: -20px !important;
  }
  .sol-mint-rabbit .sol-row {
    flex-wrap: wrap;
    /* margin-bottom: 30px; */
    margin: 10px 0;
  }
  .sol-mint-1 .sol-mint-rabbit {
    margin: 10px 0;
  }
  .sol-mint-5 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .sol-mint-1 {
    margin: 0 auto 30px !important;
  }
  .sol-introduce .sol-section {
    margin-bottom: 40px;
  }
  .sol-mint-item,
  .main-test .sol-mint-item {
    width: calc(100vw - 80px) !important;
    height: calc(100vw - 80px) !important;
    padding: 10px;
    max-width: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sol-button {
    width: 100%;
  }
  .sol-mint-3 .sol-mint-item {
    margin: auto;
  }
  .main-test .sol-mint-1 {
    width: 100%;
  }
  .sol-mint-1 .sol-mint-item {
    margin: auto;
  }
  .sol-mint-5 .sol-button {
    background-size: 100% 66px !important;
  }
  .sol-divide {
    width: 250px;
  }
  .sol-mint-3 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 962px) and (max-width: 1280px) {
  button.sol-connect-wallet {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    display: contents;
  }

  .container .sol-introduce {
    padding: 0 20px;
  }

  img {
    max-width: 100%;
  }
  .sol-logo svg {
    width: 100px;
  }
  .sol-connect-wallet,
  .sol-connected .sol-balance {
    margin-left: 0 !important;
    width: 130px !important;
  }
  .sol-connect-wallet {
    background: url("../images/common/connect-wallet.png");
    background-size: 100% 50px;
  }
  .sol-connected {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 60%;
    transform: translateX(-60%);
    top: 0px;
  }
  button.sol-connect-wallet {
    position: absolute;
    left: 60%;
    transform: translateX(-60%);
    font-size: 12px;
  }
  .sol-connected .sol-balance {
    width: 150px;
  }
  .sol-section {
    width: 100% !important;
  }
  .sol-faq .sol-section {
    margin-bottom: 30px;
  }
  /* .sol-section {
    width: 100%;
    background: url("../images/common/section.png") no-repeat;
    background-size: 100% 200px;
    height: 200px;
  } */
  .sol-rabbit-gun {
    grid-template-columns: auto;
  }
  .sol-enjoy-item {
    flex-direction: column !important;
    margin-bottom: 30px !important;
  }
  .footer .footer-follow {
    width: 100%;
  }

  .sol-header {
    padding-top: 0;
    padding-left: 0;
    height: 100px;
  }
  .sol-main-menu {
    display: none;
  }
  h1 {
    font-size: 32px;
  }
  .sol-buy .btn-buy-sol {
    font-size: 16px;
    bottom: 10px;
  }
  .sol-introduce .sol-rabbit-blue {
    margin: 20px 0;
  }
  .sol-section h2 {
    font-size: 22px;
    padding: 20px 20px 0;
  }
  .sol-section h3 {
    padding: 0 20px;
  }
  .sol-mint-rabbit {
    padding: 20px;
  }
  .sol-enjoy h1 {
    padding: 50px 20px;
  }
  .sol-enjoy-content {
    width: 100%;
  }
  .sol-enjoy-border {
    font-size: 20px;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .sol-enjoy-border > div {
    padding: 10px 20px;
  }
  .footer {
    padding: 0 20px;
  }
  .footer .footer-follow {
    width: 100%;
    margin: 0 0 50px;
  }
  .copyright {
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 0;
  }
  .copyright .sol-row {
    flex-direction: column-reverse !important;
  }

  .copyright-right .footer-icons {
    margin-top: 0;
  }
  .copyright .copyright-right img {
    margin-left: 0;
  }

  .sol-main-menu {
    position: absolute;
    top: -100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: black;
    opacity: 0.95;
    color: #fafafa;
    transition: top 0.3s ease;
    z-index: 2;
    margin-top: 0;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 60px;
  }

  .sol-main-menu.open {
    top: 0;
  }

  .sol-main-menu li {
    animation: 1s appear forwards;
    animation-delay: 1s;
    width: 100%;
    box-shadow: none !important;
    background: transparent !important;
    padding: 0 !important;
    border-color: #fff !important;
  }

  .sol-main-menu li a {
    color: #fff;
    transition: color 0.2s ease-in-out;
    animation: 0.5s slideIn forwards;
    animation-delay: 1s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .sol-rarity-item {
    padding: 0 20px;
    margin-bottom: 0;
  }
  .sol-roadmap .sol-enjoy-item {
    flex-direction: column-reverse !important;
    padding: 0;
  }
  .sol-roadmap .sol-enjoy-item .sol-enjoy-border {
    width: 100%;
  }
  .sol-roadmap .sol-title {
    font-size: 22px;
  }
  .sol-faq {
    padding: 0 10px;
  }
  /* .sol-faq .sol-section {
    width: 100%;
     background: url("../images/common/section.png") no-repeat;
    background-size: 100% 270px; 
     height: 270px;
    margin-bottom: 20px;
  } */

  .sol-section h3 {
    font-size: 18px;
    text-align: left;
  }
  .sol-section h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: center;
  }
  .footer .sol-follow {
    padding: 0 20px;
    font-size: 20px;
  }
  .sol-mint-btn-text {
    font-size: 16px;
  }
  .sol-introduce h1 {
    letter-spacing: 0;
    font-size: 24px;
  }
  .sol-mint-rabbit .sol-row {
    flex-wrap: wrap;
    /* margin-bottom: 30px; */
  }
  .sol-mint-5 {
    width: 100%;
  }
  .sol-mint-1 {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .sol-mint-5 img:not(:last-child) {
    margin-right: 0px;
  }
  .sol-mint-item,
  .main-test .sol-mint-item {
    width: calc(100vw - 80px) !important;
    height: calc(100vw - 80px) !important;
    padding: 10px;
    max-width: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sol-button {
    width: 100%;
  }

  .sol-mint-3 .sol-mint-item {
    margin: auto;
  }
  .sol-mint-3 {
    margin: auto;
  }
  .main-test .sol-mint-1 {
    width: auto;
  }
  .sol-mint-item h3 {
    font-size: 26px;
  }
  .sol-mint-item h4,
  .sol-price {
    font-size: 22px;
  }
  /* .sol-mint-5 .sol-divide {
    margin: auto;
  } */
  .sol-mint-5 .sol-button {
    background-size: 100% 60px;
  }
  .sol-mint-5 .sol-mint-item {
    margin: auto;
  }
  .sol-rabbit-waiting {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .sol-divide {
    margin: 10px auto;
    width: calc(100vw / 2);
  }
  .sol-mint-icons {
    /* height: 120px; */
    top: -30px;
    position: relative;
  }
  .main-test .sol-mint-icons {
    top: 0;
  }
  .main-test .sol-mint-1 {
    margin-right: 0;
    width: auto;
  }

  .sol-mint-5 .sol-row img {
    max-width: 20%;
  }
  .sol-mint-rabbit .sol-row {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .sol-connected .sol-balance {
    background: url("../images/common/balance.png") no-repeat;
    background-size: 100% 50px;
  }
  .sol-rabbit-waiting {
    margin-top: -20px;
  }
  .sol-mint-item .sol-row,
  .sol-mint-1 .sol-mint-icons {
    margin: -20px 0 20px;
  }
}

@media only screen and (max-width: 360px) {
  .sol-mint-item .sol-row {
    margin-bottom: 0;
  }
  .sol-mint-item h3 {
    font-size: 20px;
  }
  .sol-mint-item h4,
  .sol-price {
    font-size: 16px;
  }

  .sol-rabbit-waiting {
    margin-top: 0;
  }
  .sol-button {
    background-size: 100% 40px !important;
  }
  .sol-mint-btn-text {
    font-size: 14px;
  }
  .sol-buy .btn-buy-sol {
    bottom: 15px;
  }
}
