@font-face {
  font-family: "Prompt-Bold";
  src: local("Prompt-Bold"), url(../fonts/Prompt-Bold.ttf) format("woff");
}
@font-face {
  font-family: "Prompt-Regular";
  src: local("Prompt-Regular"), url(../fonts/Prompt-Regular.ttf) format("woff");
}

@font-face {
  font-family: "ApercuPro-Regular";
  src: local("ApercuPro-Regular"),
    url(../fonts/ApercuPro-Regular.ttf) format("woff");
}

@font-face {
  font-family: "ApercuPro-Bold";
  src: local("ApercuPro-Bold"), url(../fonts/ApercuPro-Bold.ttf) format("woff");
}

body {
  background: radial-gradient(
      closest-side at 50% 27%,
      #41509f 0%,
      #3c438e 20%,
      #220036 100%
    )
    no-repeat;
  opacity: 1;
  margin: 0;
  font-family: ApercuPro-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.container {
  width: 1280px;
  margin: auto;
}

h1 {
  text-align: center;
  font-size: 42px;
  letter-spacing: 0.42px;
  color: #ffffff;
  /* font-family: Prompt-Bold; */
  font-family: ApercuPro-Bold;
}

h4 {
  text-align: center;
  font-size: 21px;
  letter-spacing: 0.44px;
  color: #ffffff;
  margin: 0;
}

.sol-header {
  padding-top: 33px;
  padding-left: 37px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sol-main-menu {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  display: flex;
  list-style-type: none;
}

.sol-main-menu li {
  background: #0068ac73 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00ddff;
  border: 1px solid #00ddff;
  opacity: 1;

  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.sol-main-menu li a {
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.34px;
  color: #ffffff;
  text-shadow: 0px 0px 9px #00ddff5e;
  opacity: 1;
  text-decoration: none;
  font-family: ApercuPro-Bold;
}

.sol-main-menu li a:hover {
  color: #00ffe6;
}

.sol-logo img {
  height: 75px;
}

.sol-logo svg {
  overflow: inherit;
}
.sol-logo a {
  text-decoration: none;
}

/* .sol-main-menu li:last-child {
  color: #fff;
  position: relative;
  width: 174px;
} */
/** CONNECT WALLET **/
.sol-connect-wallet {
  background: url("../images/common/connect-wallet.png");
  background-color: transparent !important;
  height: 50px;
  margin-left: 20px !important;
  width: 174px;
  background-repeat: no-repeat;
  text-align: center;
  padding: 0 !important;
  overflow: hidden;
  position: relative;
  top: 12px;
}

/** Introduce **/
.sol-introduce {
  text-align: center;
}
.sol-introduce h1 {
  text-align: center;
  letter-spacing: 3.23px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 15px;
}
.sol-introduce h4 {
  text-align: center;
  font-size: 21px;
  letter-spacing: 0.44px;
  color: #ffffff;
  margin: 0;
}
.sol-buy {
  text-align: center;
  position: relative;
  margin-top: 30px;
  height: 51px;
  cursor: pointer;
}

.sol-buy .btn-buy-sol {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 23px;
  letter-spacing: 0.48px;
  color: #ffffff;
  font-weight: bold;
  /* font-family: Prompt-Bold; */
}

.sol-buy svg {
  filter: drop-shadow(0px 16px 0px rgba(0, 16, 45, 1));
  overflow: visible;
  width: 305px;
  height: 51px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#button_dn {
  fill: url(#button_dn);
}

.sol-introduce .sol-rabbit-blue {
  margin: 30px 0;
  width: 800px;
}

.sol-section {
  background: url("../images/common/section.png") no-repeat;
  align-items: center;
  width: 765px;
  margin: auto;
  background-size: 100% 100% !important;
  padding: 20px 0;
}

.sol-section.sol-long-content {
  background: url("../images/common/section.png") no-repeat;
  background-size: 100% 100% !important;
}

.sol-section h2 {
  padding: 10px 60px 0 60px;
  text-align: left;
  font-size: 29px;
  letter-spacing: 0.61px;
  color: #ffffff;
  text-shadow: 0px 0px 16px #00b0ea;
  margin: 0;
  line-height: 39px;
  margin-bottom: 15px;
}

.sol-section h3 {
  text-align: left;
  font-size: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  margin: 0;
  line-height: 29px;
  padding: 0 40px;
}
.sol-section a {
  color: #fff;
  text-decoration: none;
}

.sol-rabbit-gun {
  display: grid;
  grid-template-columns: 245px 245px 245px;
  grid-gap: 30px;
  margin: auto;

  justify-content: center;
  margin: 50px 0 124px 0;
}

.sol-rabbit-gun img {
  width: 100%;
}

/** MINT RABBIT **/
.sol-mint-rabbit {
  background: linear-gradient(
    180deg,
    rgb(60, 164, 202) 0%,
    33.3992%,
    rgb(121, 86, 233) 66.7984%,
    83.3992%,
    rgb(141, 43, 233) 100%
  );
  padding: 53px 0 80px;
}

.sol-mint-rabbit h1 {
  text-align: center;
  letter-spacing: 0.42px;
  color: #ffffff;
}

.sol-mint-item {
  box-shadow: 0px 0px 16px #00ddff;
  border: 6px solid #ffffff;
  border-radius: 33px;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.sol-mint-item h3 {
  font-size: 31px;
  letter-spacing: 8px;
  margin: 0;
}

.sol-mint-item h4,
.sol-price {
  font-size: 24px;
  font-family: "ApercuPro-Bold";
  letter-spacing: 1px;
}

.sol-divide {
  height: 6px;
  background: #fff;
  border-radius: 2px;
  margin: 15px 80px 0;
}

.sol-mint-1 .sol-mint-item {
  width: 400px;
  height: 400px;
}

.sol-mint-1 .sol-divide {
  box-shadow: 0px 0px 16px #00ddff;
}
.sol-mint-3 .sol-divide {
  box-shadow: 0px 0px 16px #c305ff;
}
.sol-mint-5 .sol-divide {
  box-shadow: 0px 0px 16px #ddff05;
  width: 55%;
  margin: 15px auto;
}
.sol-mint-5 .sol-row svg {
  max-width: 20%;
}
.sol-mint-btn-text {
  letter-spacing: 2px;
  color: #ffffff;
  text-shadow: 0px 4px 6px #000000a6;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 15px;
}
.sol-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sol-mint-rabbit .sol-row {
  margin: 67px 0 83px;
}

.sol-mint-1 {
  margin-right: 0;
  /* margin-right: 83px; */
}

.main-test .sol-mint-1 {
  margin-right: 83px;
  width: 380px;
}

.main-test .sol-mint-1 .sol-mint-item {
  width: 100%;
  height: auto;
}

.sol-mint-1 .sol-button {
  background: url("../images/common/radish-button-1.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-1 .sol-button:hover {
  background: url("../images/common/radish-button-1-hover.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-3 {
  width: 478px;
}

.sol-mint-3 .sol-button {
  background: url("../images/common/radish-button-3.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-3 .sol-button:hover {
  background: url("../images/common/radish-button-3-hover.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-5 {
  width: 941px;
  margin: auto;
}

.sol-mint-5 .sol-button {
  background: url("../images/common/radish-button-5.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-5 .sol-button:hover {
  background: url("../images/common/radish-button-5-hover.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.sol-mint-5 img:not(:last-child) {
  margin-right: 17px;
}

.sol-button {
  position: relative;
  height: 66px;
  cursor: pointer;
}

.sol-rabbit-waiting {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: "ApercuPro-Bold";
  letter-spacing: 1px;
}

.sol-mint-item .sol-row {
  margin: 0;
}

.sol-mint-item .sol-row svg,
.sol-mint-item .sol-mint-icons svg {
  height: 90px;
  margin: 10px 0;
}

.sol-mint-3 .sol-mint-item {
  box-shadow: 0px 0px 16px #c305ff;
}

.sol-mint-5 .sol-mint-item {
  box-shadow: 0px 0px 16px #ddff05;
}

/** ENJOY **/

.sol-enjoy h1 {
  padding: 160px 0 71px;
  margin: 0;
}

.sol-enjoy-item {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sol-enjoy-item:not(:nth-child(2)) {
  align-items: center;
}
.sol-enjoy-item:not(:last-child) {
  margin-bottom: 191px;
}

.sol-enjoy-item img {
  width: 400px;
}

.sol-enjoy-item:nth-child(odd) {
  flex-direction: row-reverse;
}

.sol-enjoy-border {
  box-shadow: -30px 0px 30px -25px #c500ee;
  mix-blend-mode: hard-light;
  background: linear-gradient(270deg, #00ddff00 0%, #ee00ffe3 100%);
  width: 577px;
  height: 350px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  font-size: 26px;
  letter-spacing: 1.98px;
  color: #ffffff;
  text-shadow: 0px 3px 13px #000c1194;
  font-weight: bold;
}

.footer .footer-follow {
  background: linear-gradient(270deg, #2c25fe 0%, #cb00ff 100%, #00ddff00 100%);
  box-shadow: 0px -6px 52px #0e0e57;
  width: 1009px;
  height: 259px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 188px auto 170px;
}

.footer-icons {
  margin-top: 20px;
  display: flex;
}

.footer-icons img:first-child {
  margin-right: 20px;
}
.footer .sol-follow {
  font-size: 26px;
  letter-spacing: 1.98px;
  color: #ffffff;
  text-shadow: 0px 3px 13px #000c1194;
  font-weight: bold;
}

.copyright {
  height: 131px;
  background: #000;
}

.copyright .copyright-left {
  letter-spacing: 2.27px;
  color: #ffffff;
  text-align: center;
  flex: 1;
}

.copyright .sol-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 131px;
}

.copyright .copyright-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.copyright .copyright-right span {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 4.18px;
}

.copyright .copyright-right img {
  margin-left: 30px;
}

/** ATTRIBUTES **/
.sol-attribute-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 198px 198px 198px 198px;
  grid-gap: 47px;
  margin-bottom: 100px;
}
.sol-attribute-grid img {
  width: 100%;
}

.sol-attribute-item {
  text-align: center;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.44px;
}
.sol-attribute-name {
  margin-top: 19px;
}

/** ROADMAP **/
.sol-roadmap img {
  width: 108px;
}

.sol-roadmap .sol-enjoy-item {
  align-items: center !important;
  justify-content: flex-start;
  padding: 0 135px;
}

.sol-roadmap .sol-enjoy-item .sol-enjoy-border {
  width: 700px;
}

.sol-roadmap h1 {
  margin-bottom: 50px;
}

.sol-roadmap .sol-title {
  font-weight: 900;
  text-align: center;
  padding-bottom: 20px;
  font-size: 33px;
}

.sol-enjoy-border > div {
  width: 100%;
}

/** FAQ **/
.sol-faq .sol-section {
  margin-bottom: 94px;
}

.MuiPaper-root {
  color: #fff !important;
}

.MuiDialogContent-root .MuiList-root {
  background: #fff !important;
}

.MuiDialogContent-root .MuiListItem-root .MuiButton-root {
  border-bottom: 1px solid #dedede !important;
  border-radius: 0 !important;
}

/** loading **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.sol-buy .lds-ellipsis {
  position: absolute;
  transform: translateX(-280px) translateY(-15px);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/** DISALOG **/
.sol-dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(255 255 255 / 60%);
}

.sol-dialog .alert {
  padding: 10px;
  color: #0aece8;
  font-size: 21px;
  font-weight: bold;
  margin: 100px auto;
  width: 900px;
}
.sol-dialog .alert img {
  margin-right: 20px;
}
.sol-dialog .success {
  background: #5656ff;
}

.sol-dialog .warning {
  background: #00102d;
}
.sol-dialog .error {
  background: #e74f4f;
}

.sol-connected {
  position: relative;
  top: 10px;
  color: #fff;
}

.sol-connected .sol-connect-wallet {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sol-connected .sol-balance {
  background: url("../images/common/balance.png") no-repeat;
  background-color: transparent !important;
  height: 50px;
  margin-left: 20px !important;
  width: 100%;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 8px;
}

.sol-connected .sol-balance img {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.sol-rarity-item {
  text-align: center;
  margin-bottom: 30px;
}

.sol-rarity-item h1 {
  margin-bottom: 10px;
}

.sol-countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
}
.sol-mint-rabbit .sol-countdown {
  margin-bottom: 30px;
}
.sol-countdown-col {
  width: 80px;
}
.sol-countdown .sol-time {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
}

.sol-countdown-col div {
  text-transform: uppercase;
  text-align: center;
}

/** MENU MOBILE **/
.sol-nav-menu {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 10;
}

.sol-line {
  height: 2px;
  width: 30px;
  background: #fff;
  transition: all 0.2s ease;
}
.sol-line-top {
  transform: none;
  transform-origin: top left;
  margin-bottom: 8px;
}
.sol-line-middle {
  opacity: 1;
  transform: none;
}

.sol-line-bottom {
  transform: none;
  transform-origin: top left;
  margin-top: 8px;
}

.open .sol-line-top {
  transform: rotate(45deg);
}

.open .sol-line-middle {
  transform: rotate(45deg);
  opacity: 0;
}

.open .sol-line-bottom {
  transform: translateX(-1px) rotate(-45deg);
}

.MuiButton-label {
  font-family: "ApercuPro-Bold";
}

/** SETTING **/
.sol-setting {
  background-color: #fff;
  padding: 20px;
  min-height: 500px;
  margin-top: 50px;
}

.sol-col {
  display: flex;
  flex-direction: column;
}

.sol-setting form {
  border: 3px solid #f1f1f1;
  max-width: 400px;
  margin: auto;
  padding: 15px;
}

.sol-setting input[type="text"],
.sol-setting input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  flex: 1;
}

.sol-setting button {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.sol-setting button:hover {
  opacity: 0.8;
}

.sol-setting .sol-row {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.sol-setting .sol-row label {
  width: 150px;
}
.sol-setting h3 {
  text-align: center;
}

/**
* Mint Tool
**/
.sol-tool .sol-row-label {
  height: 38px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sol-tool .sol-row {
  align-items: center;
}

.sol-tool .sol-row-input {
  height: 28px;
  flex: 1;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 4px 12px;
}

.sol-tool .sol-row-button {
  width: 120px;
  height: 38px;
}

.sol-history table {
  width: 100%;
}

.sol-history td,
.sol-history th {
  border: 1px solid #ddd;
  padding: 8px;
}

.sol-history tr:nth-child(even) {
  background-color: #f2f2f2;
}

.sol-history tr:hover {
  background-color: #ddd;
}

.sol-history th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.sol-history .sol-history-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

.sol-history button {
  width: 120px;
  margin-left: 20px;
}

.sol-history-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 50%);
}
